.cmd_footer{
    flex-grow: 1 ;
    text-align:right;
    color:grey;
    padding:5px 10px;
}
.department_card{
    margin-top: 10vh;
    padding: 20px;
}
.card_bottom_number{
    font-weight:bold;
    margin-left:15px;
    color:black;
    text-align:left;
    margin-top:15px;
}
.card_text{
    font-weight:bold;
    margin-left:15px;
    color:black;
}
.card_icon{
    /* height: 30;
    width: 30; */
    color: #005AC1 ;
    padding:15px;
    background-color:#D8E2FF;
    /* margin:10px; */
    /* margin-right: 10px; */
}
.card_icon_size{
    border-radius:50px;
    padding:15px;
    background-color:#D8E2FF;
    /* height:30;
    width:40px; */
    margin-right: 10px; 
}
.general_info_bottom_card{
    text-align: left;
}