/* .reload_text_class::after {
    content: "CMD's Dashboard";
    display: none;
    font-size: 30px;
}*/
.reload_text_class{
    font-size: 30px;
}
@media (max-width: 500px) {
    .reload_text_class {
        font-size: 20px;
    }
} 
.full_drawer{
    min-height: 100vh;
    background-color: #D8E2FF;
    ;
}
.active_menu{
    background-color: #eff1f3;
    color: #2F80ED !important;
    max-width: 100%;
    height: 100%;
    width: 100%;
    border-radius: 5px;
}
.top_drawer{
    position: sticky;
    top: 0;
    overflow: hidden;
    /* height: 46vh; */
}
.middle_drawer0 {
    top: 46vh;
    overflow: hidden;
    max-height: 44vh;
    padding: 0px 0px 0px 10px;
}
.middle_drawer1 {
    top: 46vh;
    overflow: hidden;
    max-height: 25vh;
}

.middle_drawer1:hover {
    overflow-y: auto;
}

.middle_drawer1::-webkit-scrollbar {
    width: 4px;
    border-radius: 50px;
}

.middle_drawer1::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 50px;
}

.middle_drawer1::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;
}

.middle_drawer1::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 50px;
}
.middle_drawer2 {
    /* top: 50vh; */
    overflow: hidden;
    max-height: 25vh;
}

.middle_drawer2:hover {
    overflow-y: auto;
}

.middle_drawer2::-webkit-scrollbar {
    width: 4px;
    border-radius: 50px;
}

.middle_drawer2::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 50px;
}

.middle_drawer2::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;
}

.middle_drawer2::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 50px;
}

.bottom_drawer{
    width: 200px ;
    position: fixed;
    top: 90vh;
    overflow: hidden;
    max-height: 10vh;
}