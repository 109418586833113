
.cmd_main{
    margin-top: 12vh;
    height: 80vh;
    margin-left: 15px;
    margin-right: 15px;
    background-color: white;
    border-radius: 16px;
    padding: 10px;
}
.cmd_calender{
    height: 80vh;
}
.rbc-timeslot-group{
    min-height: 80px;
}
.rbc-label{
    font-size: 13px;
}
.rbc-event-content{
    font-size: 11px;
}
.rbc-event-label{
    display: none;
}
.rbc-events-container{
    margin-right: 0px !important;
}
 .rbc-past-date {
    background-color: #bfbdbd ;
}
.rbc-day-slot .rbc-time-slot {
    border-top: none;
} 
.rbc-today {
    background-color: #8AE680;
}
.rbc-event{
    /* left: 0px !important; */
    /* width: 100% !important; */
}
.cmd_footer{
    flex-grow: 1 ;
    text-align:right;
    color:grey;
    padding:10px 20px;
}

.MuiStack-root.css-4jnixx-MuiStack-root {
    padding-top: 0 !important;
}