.home-1920-login,
.home-1920-login * {
  box-sizing: border-box;
}

.home-1920-login {
  background: var(--neutralgrey, #eff1f3);
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.body {
  background: var(--Neutral-grey, #EFF1F3);
  ;
}

.frame-85 {
  background: rgba(255, 255, 255, 0.16);
  border-radius: 10px;
  padding: 32px 29.5px 32px 29.5px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% - 210px);
  top: calc(50% - 356px);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
}

.image-1 {

  flex-shrink: 0;
  width: 300px;
  height: 80px;
  position: relative;
}

@media (max-width: 500px) {
  .image-1 {
    width: 162px;
    /* Adjust the width as needed for your mobile design */
    height: 50px;
    /* To maintain aspect ratio */
  }
}

/* .image-3 {
  flex-shrink: 0;
  width: 120px;
  height: 30px;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  margin-top: 15px;
} */
.strong_password{
  color:#606060;
  font-size: 14px;
}
.login {
  color: var(--primaryblue, #2f80ed);
  text-align: center;
  font: 500 24px/26px "Roboto", sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0 0 0;

}

@media (max-width: 500px) {
  .login {
    padding: 5px;
    /* Adjust the width as needed for your mobile design */
    /* To maintain aspect ratio */
  }
}

.please-enter-your-account-credentials {
  color: var(--darkgrey, #606060);
  text-align: center;
  font: 400 16px "Roboto", sans-serif;
  position: relative;
  align-self: stretch;
  padding: 32px 0px;
}

@media (max-width: 500px) {
  .please-enter-your-account-credentials {
    color: var(--darkgrey, #606060);
    text-align: center;
    font: 300 13px "Roboto", sans-serif;
    position: relative;
    align-self: stretch;
    padding: 0px 0px 10px 0px;
  }
}

.password_field {
  margin-top: 25px;
}
@media (max-width: 500px) {
  .password_field {
    margin-top: 15px;
  }
}
.forgot_password {
  margin-top: 21px;
 
  text-decoration: none;
  font-size: 16px ;
}
@media (max-width: 500px) {
  .forgot_password {
    margin-top: 10px;
   
    text-decoration: none;
    font-size: 13px ;
    text-align: right;
  }
}
input:-internal-autofill-selected {
  background-color:-internal-light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;
}
.submit_btn{
  margin-top: 30px;
  margin-bottom: 17px;
}
@media (max-width: 500px) {
  .submit_btn{
    margin-top: 10px;
    margin-bottom: 17px
  }
}
.footer_image{
  margin-top: 5px;
}
@media (max-width: 500px) {
  .footer_image{
    margin-top: 5px;
    height:25px;
    
  }
}
.group-42 {
  flex-shrink: 0;
  width: 361px;
  height: 255px;
  position: static;
}

.asset-component-button {
  border-radius: 4px;
  border-style: solid;
  border-color: var(--darkgrey, #606060);
  border-width: 1px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: center;
  width: 361px;
  height: 52px;
  position: absolute;
  left: 29.5px;
  top: 253px;
  overflow: hidden;
}

.unstyled-button {
  padding: 8px 22px 8px 22px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.masked-icon {
  flex-shrink: 0;
  width: 18px;
  height: 22px;
  position: relative;
}

.asset-icon-profile {
  position: absolute;
  left: -6px;
  top: -1px;
  overflow: visible;
}

.button {
  color: var(--gray-3, #828282);
  text-align: left;
  font: 500 15px/26px "Roboto", sans-serif;
  position: relative;
}

.group-41 {
  width: 361px;
  height: 52px;
  position: static;
}

.asset-component-button2 {
  border-radius: 4px;
  border-style: solid;
  border-color: var(--darkgrey, #606060);
  border-width: 1px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: center;
  width: 361px;
  height: 52px;
  position: absolute;
  left: 29.5px;
  top: 335px;
  overflow: hidden;
}

.asset-icon-lock {
  position: absolute;
  left: -6px;
  top: -1px;
  overflow: visible;
}

.asset-icon-visibility {
  position: absolute;
  left: 352.39px;
  top: 349px;
  overflow: visible;
}

.asset-component-button3 {
  background: var(--blue-1, #2f80ed);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: center;
  width: 361px;
  position: absolute;
  left: 29.5px;
  top: 466px;
  box-shadow: var(--elevation-2-box-shadow,
      0px 1px 5px 0px rgba(0, 0, 0, 0.12),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 3px 1px -2px rgba(0, 0, 0, 0.2));
  overflow: hidden;
}

.button2 {
  color: var(--primary-contrast, #ffffff);
  text-align: left;
  font: var(--components-button-large, 500 15px/26px "Roboto", sans-serif);
  text-transform: uppercase;
  position: relative;
}

.forgot-password {
  color: var(--blue-1, #2f80ed);
  text-align: left;
  font: 500 16px/26px "Roboto", sans-serif;
  position: absolute;
  left: calc(50% - 68.5px);
  top: 408px;
}

.deep-probe-branding {
  position: absolute;
  left: calc(50% - 70px);
  top: 600px;
  overflow: visible;
}

/* ----------------*/
.paper_style {
  padding: 20;
  height: '60hv';
  width: 260px;
  margin: 20px auto;
}

.card {
  display: flex;
  padding: 64px 59px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.16);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.30);
}
.input-text {
  color: #000; /* Set text color to black */
}